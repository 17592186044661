import React, { useContext, useEffect, useState } from "react";
import Header from "../Coman/Header";
import Sidebar from "../Coman/Sidebar";
import axios from "axios";
import { ProjectContext } from "../../ProjectContext";
import Pagination from "../Coman/Pagination";

export default function Withdrawal() {
  const {
    account,
    toastSuccess, dbbalance,
    tokenrate,
    toastError, dbuser,
    getuserDB,
    connectMetamask,
  } = useContext(ProjectContext)

  const [amount, setamount] = useState(0);
  const [rcv_amount, setrcv_amount] = useState(0);
  const [fees, setfees] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [data, setdata] = useState([]);
  const [datap, setdatap] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pages, setPages] = useState(1);

  const getDataPending = async () => {
    if (!account) {
      return;
    }

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getpbyid",
        address: account,
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        if (res.data.error) {
          return "";
        }
        setdatap(res.data.data);
      });
  };

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "getbyid",
        // address: "default",
        address: account,
        key: process.env.REACT_APP_KEY,
        page: currentPage,
        pageSize: pageSize
      })
      .then((res) => {
        setisLoading(false);
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          return "";
        }
        setdata(res.data.data);
        setPages(Math.ceil(res.data.dataLength / pageSize));
      });
  };
  useEffect(() => {
    getData();
  }, [account, pages, pageSize, currentPage]);
  useEffect(() => {
    getDataPending()
  }, [account]);

  const withdraw = async () => {
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    if (dbuser.restake) {
      toastError("Restake account");
      settrdone(false);
      return;
    }
    if (amount < 10) {
      toastError("Min $10 required");
      settrdone(false);
      return;
    }
    if (Number(amount) > dbuser.balance) {
      toastError("Insufficient Balance");
      settrdone(false);
      return;
    }
    await axios
      .post(process.env.REACT_APP_BACKEND_LINK, {
        method: "withdrawal",
        submethod: "insert",
        key: process.env.REACT_APP_KEY,
        address: account,
        amount: amount,
        token: total_tokens,
        fee: fees,//admin
        t_rcv: rcv_amount,
        rate: tokenrate,
      })
      .then((res) => {
        // var dedata = decryptData(res.data);
        if (res.data.error) {
          toastError(res.data.error);
          return "";
        }
        toastSuccess(res.data.data);
      });
    getuserDB();
    getDataPending()
    getData()
    settrdone(false);
  }
  useEffect(() => {
    // var tmt = amount / tokenrate;
    var tmt = amount;
    var tmtfee = (tmt * 10) / 100;
    // var tmtfee = 0;
    settotal_tokens(tmt);
    setfees(tmtfee);
    setrcv_amount(tmt - tmtfee);
  }, [amount, tokenrate]);



  return (
    <>
      <Header />
      <Sidebar />
      <div className="admin-wrapper">
        <div className="about-area">
          <div className="container-fluid">
            <div className="row g-4 justify-content-center">
              <h5 className=" text-start">Withdrawal </h5>
              <div className="create-new-wrapper">
                <div className="container">
                  <div className="row g-5 justify-content-center">
                    <div className="col-12 col-lg-8">
                      <div className="create-new-form border shadow-sm p-4 p-sm-5 card about-card shadow-sm ">
                        <h5 className="mb-4">Withdrawal</h5>
                        <div className="row align-items-center">
                          <div className="col-12">
                            <div className="form-group mb-4 text-start">
                              <div className="d-flex justify-content-between">
                                <label className="mb-2 fz-16" htmlFor="withd">
                                  Enter Amount
                                </label>
                                <label className="mb-2 fz-16" htmlFor="withd">
                                  Balance: ${dbuser ? dbuser.balance : 0}
                                </label>
                              </div>
                              <input
                                className="form-control"
                                id="withd"
                                value={amount}
                                onChange={(e) =>
                                  setamount(e.target.value)
                                }
                                placeholder="Enter Amount"
                              />
                              
                              <div className="d-flex justify-content-between pt-2">
                                <div className="mb-2 fz-16" >
                                  Fee
                                </div>
                                <div className="mb-2 fz-16" >
                                  ${fees}
                                </div>
                              </div>
                              <div className="d-flex justify-content-between ">
                                <div className="mb-2 fz-16" >
                                  Total Receive
                                </div>
                                <div className="mb-2 fz-16" >
                                  ${rcv_amount}
                                </div>
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-primary py-2 mt-3 " onClick={() => withdraw()} >Withdrawal</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {datap.length ?
              <div className="row g-4 text-start">
                <div className="col-12">
                  <h5 class=" text-start mb-4 dart-text">Pending Withdraw </h5>
                  <div className="card border-0 shadow-sm">
                    <div className="card-body p-4">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="tab1"
                          role="tabpanel"
                          aria-labelledby="tab--1"
                        >
                          <div className="table-responsive border shadow-sm dashboard-table activity-table">
                            <table className="table mb-0">
                              <tbody>
                                <tr className="bg-tble">
                                  <th className="text-black"> #</th>
                                  <th className="text-black">Date</th>
                                  <th className="text-black">Amount</th>
                                  {/* <th className="text-black">Fee</th> */}
                                  {/* <th className="text-black">Received Amount</th> */}
                                  <th className="text-black">Status</th>
                                </tr>
                                {datap.map((ele, i) => {
                                  return (
                                    <tr>
                                      <th> {ele.id}</th>
                                      <th>{ele.createdAt}</th>
                                      <th>$ {ele.amount} </th>
                                      {/* <th>${ele.fee}</th> */}
                                      {/* <th>${ele.t_rcv}</th> */}
                                      <th>Pending</th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              : ''}
            <div className="row g-4 text-start pt-4">
              <div className="col-12">
                <h5 class=" text-start mb-4 dart-text">Withdraw History </h5>
                <div className="card border-0 shadow-sm">
                  <div className="card-body p-4">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="tab1"
                        role="tabpanel"
                        aria-labelledby="tab--1"
                      >
                        <div className="table-responsive border shadow-sm dashboard-table activity-table">
                          <table className="table mb-0">
                            <tbody>
                              <tr className="bg-tble">
                                <th className="text-black"> #</th>
                                <th className="text-black">Date</th>
                                <th className="text-black">Amount</th>
                                {/* <th className="text-black">Received Amount</th> */}
                                <th className="text-black">Status</th>
                              </tr>
                              {data.map((ele, i) => {
                                return (
                                  <tr>
                                    <th> {ele.id}</th>
                                    <th>{ele.createdAt}</th>
                                    <th>$ {ele.amount} </th>
                                    {/* <th>${ele.fee}</th> */}
                                    {/* <th>${ele.t_rcv}</th> */}
                                    <th>Success</th>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  pages={pages}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
