import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from './Components/Dashboard/Dashboard';
import Market from './Components/Dashboard/Market/Market';
import Subscriptions from './Components/Dashboard/Subscriptions/Subscriptions';
import Profile from './Components/Dashboard/Profile/Profile';
import Purchase from './Components/Dashboard/Purchase/Purchase';
import MatchingIncom from './Components/Dashboard/MatchingIncom/MatchingIncom';
import Sponsor from './Components/Dashboard/Spounsor/Sponsor';
import Performanceincome from "./Components/Dashboard/Performance/Performanceincome";
import Withdrawal from "./Components/Dashboard/Withdrawal";
import TreeView from "./Components/Dashboard/Tree/Tree";
import View from './Components/Dashboard/Profile/View';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/market" element={<Market />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/matching-incom" element={<MatchingIncom />} />
          <Route path="/sponsor-incom" element={<Sponsor />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/tree" element={<TreeView />} />
          <Route path="/purchase-history" element={<Purchase />} />
          <Route path="/performance-income" element={<Performanceincome />} />
          <Route path="/withdrawal" element={<Withdrawal />} />
          <Route path="/view" element={<View />} />
        </Routes>
      </BrowserRouter>
    </div>

  );
}

export default App;
