import React, { useContext, useEffect, useState } from "react";
import Header from "../../Coman/Header";
import Sidebar from "../../Coman/Sidebar";
import Tree from "react-d3-tree";
import axios from "axios";
import { Modal } from 'react-bootstrap';

import { ProjectContext } from "../../../ProjectContext";

export default function TreeView() {
  const { getudata, account, copyaddress, formatAddress, copyText } = useContext(ProjectContext);

  const [data, setdata] = useState({ name: "you" });
  const [isLoading, setisLoading] = useState(false);
  const [translate, setTranslate] = useState({ x: 0, y: 0 });
  const [show, setShow] = useState(false);
  const [popupdataloading, setpopupdataloading] = useState(false);
  const [popupdata, setpopupdata] = useState(null);
  const handleClose = () => setShow(false);

  const CustomLabel = ({ nodeData }) => (
    <g>
      <text x="0" y="0" textAnchor="middle" fill="#fff">
        {nodeData.name}
      </text>
      <text x="0" y="20" textAnchor="middle" fill="#fff">
        Business: ${nodeData.balance || 0}
      </text>
    </g>
  );

  const treeConfig = {
    nodeSize: { x: 800, y: 500 },
    separation: { siblings: 0.6, nonSiblings: 0.8 },
    initialDepth: 1,
    nodeSvgShape: {
      shape: "rect",
      shapeProps: {
        width: 0,
        height: 0,
        x: -20,
        y: 20,
        stroke: "#fff",
      },
    },
    allowForeignObjects: true,
    nodeLabelComponent: {
      render: <CustomLabel />,
      foreignObjectWrapper: {
        y: 0,
        x: 0,
      },
    },
    pathFunc: "step",
  };

  // const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
  //   <g>
  //     <foreignObject
  //       x="-150"
  //       y="-70"
  //       width="300"
  //       height="480"
  //       style={{ cursor: "pointer" }}
  //     >
  //       <div className="col-sm-12">
  //         <div className="card border-0 shadow-sm">
  //           <div className="card-body p-4 text-center">
  //             <div className="text-center">
  //               <span>
  //                 <i className="bi bi-person-circle fs-2" />
  //               </span>
  //             </div>
  //             <div className="why-us__content">
  //               <h4 type="button">
  //                 <a
  //                   className="pricing__btn-white fs-4"
  //                   type="button"
  //                   onClick={() => copyaddress(nodeDatum.name)}
  //                 >
  //                   {formatAddress(nodeDatum.name)} <i className="bi bi-copy"></i>
  //                 </a>
  //               </h4>
  //               <p className="mt-3 text-muted">
  //                 Business: ${nodeDatum.balance || 0}
  //               </p>
  //             </div>
  //             {nodeDatum.children?.length ? (
  //               <a className="mt-4" type="button" onClick={toggleNode}>
  //                 <span>
  //                   <i className="bi bi-arrow-down-circle fs-2" />
  //                 </span>
  //               </a>
  //             ) : (
  //               ""
  //             )}
  //           </div>
  //         </div>
  //       </div>
  //     </foreignObject>
  //   </g>
  // );

  const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
    <g>
      <foreignObject
        x="-150"
        y="-70"
        width="300"
        height="480"
        style={{ cursor: "pointer" }}
      >
        <div className="col-sm-12">
          <div className="card border-0 shadow-sm">
            <div className="card-body p-4 text-center">
              <div className="text-center">
                <span>
                  <i className="bi bi-person-circle fs-2" />
                </span>
              </div>
              <div className="why-us__content">
                <h4>
                  <a
                    className="pricing__btn-white fs-4"
                    onClick={() => copyaddress(nodeDatum.name)}
                  >
                    {formatAddress(nodeDatum.name)} <i className="bi bi-copy"></i>
                  </a>
                </h4>
                {/* <p className="mt-3 text-muted">
                  Business: ${nodeDatum.balance || 0}
                </p> */}
                {/* Button to open popup */}
                <button
                  className="btn btn-primary mt-3"
                  onClick={() => getPopupData(nodeDatum.name)}
                >
                  View Details
                </button>
              </div>
              {nodeDatum.children?.length ? (
                <a className="mt-4" type="button" onClick={toggleNode}>
                  <span>
                    <i className="bi bi-arrow-down-circle fs-2" />
                  </span>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </foreignObject>
    </g>
  );

  const getData = async () => {
    if (!account) {
      return;
    }
    setisLoading(true);

    try {
      const res = await axios.post(process.env.REACT_APP_BACKEND_LINK, {
        method: "user",
        submethod: "tree",
        address: account,
        key: process.env.REACT_APP_KEY,
      });
      setisLoading(false);
      console.log("Data received:", res.data);

      if (res.data.error) {
        return "";
      }

      setdata(res.data); // Ensure `balance` field exists in the response
    } catch (error) {
      setisLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const getPopupData = async (address) => {
    setShow(true)
    setpopupdataloading(true)
    var data = await getudata(address);
    if (data.data.error) {
      setpopupdata(null);
      setpopupdataloading(false)
      return "";
    }
    console.log("user tree data", data.data.data);
    setpopupdata(data.data.data);
    setpopupdataloading(false)
  }

  useEffect(() => {
    getData();
  }, [account]);

  return (
    <>
      <div>
        <Header />
        <Sidebar />
        <div className="admin-wrapper">
          <div className="container-fluid">
            <div className="row g-4 text-start">
              <div className="col-12" style={{ height: "1000px" }}>
                <span>
                  {isLoading ? (
                    <div className="text-center pt-4 fs-3">Loading...</div>
                  ) : (
                    <Tree
                      data={data}
                      orientation="vertical"
                      {...treeConfig}
                      translate={translate}
                      scaleExtent={{ min: 0.1, max: 1 }}
                      isInitialRenderForDataset={true}
                      enableLegacyTransitions={true}
                      renderCustomNodeElement={renderRectSvgNode}
                    />
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose} centered>
        <div className='d-flex justify-content-center text-dark'>
          <div class="modal-content ">
            <div className="modal-header d-flex justify-content-between">
              <h5 className="modal-title" id="exampleModalLabel">Details</h5>
              <button type="button" className="btn-sm btn-sucess" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <Modal.Body>
              <div className="row ">
                {popupdataloading ? "Loading..." :
                  <>
                    {popupdata ?
                      <>
                        <div style={{ color: "#000" }}>
                          {/* <div className=" style-border2 col-md-12 d-flex justify-content-between" >
                          <span>Rank</span> <span>{popupdata.rank ? popupdata.rank : 'No Rank'}</span></div> */}
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Address: </span> <span class="text-break text-end " onClick={() => copyText(popupdata.address)}>{formatAddress(popupdata.address)} <i className='fa fa-copy'></i></span></div>
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Referral ID: </span> <span class="text-break text-end" onClick={() => copyText(popupdata.ref_address)}>{formatAddress(popupdata.ref_address)} <i className='fa fa-copy'></i></span></div>
                          <div className="style-border2 col-md-12 d-flex justify-content-between">
                <span>Business:</span>
                <span className="text-break text-end">
                  $ {popupdata.left_leg_business + popupdata.right_leg_business}
                </span>
              </div>                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Left Leg Business:</span> <span class="text-break text-end">$ { popupdata.left_leg_business }</span></div>
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Right Leg Business:</span> <span class="text-break text-end">$ {popupdata.right_leg_business}</span></div>
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Left Leg Team :</span> <span class="text-break text-end">{popupdata.left_leg_users.length}</span></div>
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Right Leg Team :</span> <span class="text-break text-end">{popupdata.right_leg_users.length}</span></div>
                          {/* <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Self Investment:</span> <span class="text-break text-end">{popupdata.t_staking}</span></div> */}
                          <div className=" style-border2 col-md-12 d-flex justify-content-between"><span>Date of Joining:</span> <span class="text-break text-end">{popupdata.createdAt}</span></div>
                        </div>
                      </> : ''}
                  </>}
              </div>
            </Modal.Body>
          </div>

        </div>
      </Modal>
    </>

  );
}
