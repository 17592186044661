import React, { useContext, useEffect, useState } from "react";
import { ProjectContext } from "../../../ProjectContext";
import { useNavigate } from "react-router-dom";

export default function View() {
  const { toastError, toastSuccess, setaccount } = useContext(ProjectContext);
  const [oAddr, setoAddr] = useState(null);
  var navigate = useNavigate();

  const getDta = async () => {
    if (!oAddr) {
      toastError("Enter address")
      return
    }
    toastSuccess("Viewer activated")
    setaccount(oAddr)
    navigate('/')
  };


  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6 col-xl-4">
                <div className="card border-5 shadow-sm ">
                  <div className="card-body p-0 pb-3">
                    <h6 className="pt-2 pb-0 text-center">
                      User Address
                    </h6>
                    <input
                            className="form-control ps-2"
                            type="text"
                            placeholder="Enter Address"
                            value={oAddr}
                            onChange={(e) =>
                              setoAddr(e.target.value)
                            }
                          />
                    <hr className="mt-0" />
                    <div className="px-5 d-flex align-items-center justify-content-center">
                    <button class="btn btn-primary py-2 mt-3 " onClick={() => getDta()}>
                        View
                      </button>
                    </div>
                  </div>
                </div>
              </div>
    </div>
  );
}
